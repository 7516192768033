import React, { useReducer, createContext } from 'react';

import api from 'service/httpService';

import {
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  CREATE_EVENT,
  DELETE_EVENT,
  GET_EVENT,
  UPDATE_EVENT,
  GET_EVENTS,
  CLEAR_EVENT,
  CLEAR_SNACKBAR
} from '../types';
import { DEFAULT_EVENT_STATE } from './constants';
import { useTranslation } from 'hooks/useTranslation';

import eventReducer from './eventReducer';

export const EventContext = createContext();

const EventState = (props) => {
  const [state, dispatch] = useReducer(eventReducer, DEFAULT_EVENT_STATE);
  const { translate } = useTranslation();

  const setSnackbarInterval = (time = 1000) => {
    setTimeout(() => dispatch({ type: CLEAR_SNACKBAR }), time);
  };

  const updateStateEvent = (event) => dispatch({ type: UPDATE_EVENT, payload: event });
  const deleteStateEvent = (event) => dispatch({ type: DELETE_EVENT, payload: { data: event } });

  const createEvent = async (event, onSuccess) => {
    setLoading();
    try {
      const res = await api.post(`/event`, event);
      dispatch({
        type: CREATE_EVENT,
        payload: res.event,
        successMessage: res.message
      });
      setSnackbarInterval();
      if (onSuccess) onSuccess();
    } catch (err) {
      setError(err);
    }
  };

  const updateEvent = async (eventId, event, onSuccess) => {
    setLoading();
    try {
      const res = await api.put(`/event/${eventId}`, event);
      dispatch({ type: UPDATE_EVENT, payload: res.event, successMessage: res.message });
      setSnackbarInterval();
      if (onSuccess) onSuccess();
    } catch (err) {
      setError(err);
    }
  };

  const deleteEvent = async (eventId, onSuccess) => {
    setLoading();

    try {
      const res = await api.delete(`/event/${eventId}`);
      dispatch({ type: DELETE_EVENT, payload: res, successMessage: res.message });
      setSnackbarInterval();
      if (onSuccess) onSuccess();
    } catch (err) {
      setError(err);
    }
  };

  const getEvent = async (eventId) => {
    setLoading();
    try {
      const res = await api.get(`/event/${eventId}`);
      dispatch({ type: GET_EVENT, payload: res });
    } catch (err) {
      setError(err);
    }
  };

  const getEvents = async (query) => {
    setLoading();
    try {
      const res = await api.post(`/event/advancedSearchDocuments`, query);
      dispatch({ type: GET_EVENTS, payload: res.data });
    } catch (err) {
      setError(err);
    }
  };

  //Clear Event
  const clearEvent = () => dispatch({ type: CLEAR_EVENT });

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  //Set Error Msg
  const setError = async (err) => dispatch({ type: SET_ERROR, payload: translate(err.response.data) });

  return (
    <EventContext.Provider
      value={{
        ...state,
        setLoading,
        clearState,
        clearEvent,
        createEvent,
        deleteEvent,
        updateEvent,
        getEvent,
        getEvents,
        updateStateEvent,
        deleteStateEvent
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
};

export default EventState;
