import React, { useReducer } from 'react';
import UserContext from './userContext';
import UserReducer from './userReducer';
import api from '../../api/api';
import {
  GET_USERS,
  GET_USER_BY_EMAIL,
  GET_USER,
  UPDATE_USER,
  CREATE_USER,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  SEND_INVITATION,
  GET_USERS_V3,
  DELETE_USER
} from '../types';
import CustomAlert from 'components/CustomAlert';
import { DOCUMENT_DELETED_ALERT, USER_INVITED_ALERT } from 'components/CustomAlert/constants/alerts';
import { useIntl } from 'react-intl';
import { INFO_STATUS_ICON, SUCCESS_STATUS_ICON } from 'components/CustomAlert/constants/status';
import { CLEAR_ALL_USER_STATE, DEFAULT_USER_STATE } from './constants';

const UserState = (props) => {
  const intl = useIntl();

  const [state, dispatch] = useReducer(UserReducer, DEFAULT_USER_STATE);

  const getUsers = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/user`, config);
      dispatch({
        type: GET_USERS,
        payload: res.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getUserV3 = async (values) => {
    setLoading();
    try {
      const res = await api.post(`/user/getUserV3`, { ...values });
      const payload = {
        data: res.data.results.data,
        total: res.data.results.pagination.total
      };
      dispatch({ type: GET_USERS_V3, payload: payload });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err });
    }
  };

  //Get Single Item by ID
  const getUser = async (userId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/user/${userId}`, config);
      dispatch({
        type: GET_USER,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Users Item by Team
  const getUsersByTeam = async (teamId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/user/team/${teamId}`, config);
      dispatch({
        type: GET_USERS,
        payload: res.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Get Users Item by Team
  const getUsersByEmail = async (email) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/user/email/${email}`, config);
      dispatch({
        type: GET_USER_BY_EMAIL,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const createUser = async (user) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/user`, { ...user }, config);
      dispatch({ type: CREATE_USER, payload: res.data.data, successMessage: res.data.message });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err?.response?.data?.error });
    }
  };

  //Update User
  const updateUser = async (user, userId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.put(`/user/${userId}`, { ...user }, config);
      dispatch({ type: UPDATE_USER, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const sendInvitation = async (values, handleThen) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post('/user/invitation', { ...values }, config);

      dispatch({ type: SEND_INVITATION, payload: res.data.data });

      CustomAlert({
        title: intl.formatMessage({ id: USER_INVITED_ALERT }),
        icon: SUCCESS_STATUS_ICON,
        handleThen
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearUserState = (fieldsToClear = [CLEAR_ALL_USER_STATE]) => dispatch({ type: CLEAR_STATE, fieldsToClear });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  const getAdvancedSearchUsers = async (query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearUserState();
    setLoading();
    try {
      const res = await api.post(`/user/advancedSearchDocuments`, query, config);
      dispatch({ type: GET_USERS, payload: res?.data?.data, count: res?.data?.data?.count });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const removeTeamFromUser = async ({ userId, teamId }, handleThen) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/user/removeTeamFromUser`, { userId, teamId }, config);

      dispatch({ type: DELETE_USER, payload: res.data.data });
      CustomAlert({
        title: intl.formatMessage({ id: DOCUMENT_DELETED_ALERT }),
        icon: INFO_STATUS_ICON,
        handleThen
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        getUsers,
        getUser,
        createUser,
        updateUser,
        clearUserState,
        setLoading,
        getUsersByTeam,
        getUsersByEmail,
        sendInvitation,
        getUserV3,
        getAdvancedSearchUsers,
        removeTeamFromUser
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
