//Auth
export const USER_LOADED = 'USER_LOADED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_STATE = 'CLEAR_STATE';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const REGISTER_SUCCESS = 'REGISTER_SUCESS';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_AUTH_USER = 'SET_AUTH_USER ';

//token
export const CREATE_TOKEN = 'CREATE_TOKEN';
export const GET_TOKEN = 'GET_TOKEN';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';

//Insight
export const CREATE_INSIGHT = 'CREATE_INSIGHT';
export const GET_INSIGHT = 'GET_INSIGHT';

//Matrix
export const GET_MATRIX_SEGMENTATION = 'GET_MATRIX_SEGMENTATION';
export const GET_MATRIX = 'GET_MATRIX';
export const GET_MATRIX_IG = 'GET_MATRIX_IG';
export const GET_FB_INSIGHTS = 'GET_FB_INSIGHTS';
export const GET_GG_YOUTUBE = 'GET_GG_YOUTUBE';
export const GET_GG_ANALYTICS = 'GET_GG_ANALYTICS';
export const GET_GG_ADS = 'GET_GG_ADS';
export const CREATE_FACEBOOK_ADS = 'CREATE_FACEBOOK_ADS';
export const GET_FACEBOOK_ADS = 'GET_FACEBOOK_ADS';
export const SET_LOADING_ADS = 'SET_LOADING_ADS';
export const SET_LOADING_CREATIVES = 'SET_LOADING_CREATIVES';
export const GET_FACEBOOK_ADSETS = 'GET_FACEBOOK_ADSETS';
export const GET_FACEBOOK_CREATIVES = 'GET_FACEBOOK_CREATIVES';
export const SET_CLEAR_STATE_ADS = 'SET_CLEAR_STATE_ADS';
export const GET_GOOGLE_ADS_AD_GROUP = 'GET_GOOGLE_ADS_AD_GROUP';
export const SET_LOADING_AD_GROUP = 'SET_LOADING_AD_GROUP';
export const GET_GOOGLE_ADS_KEYWORD = 'GET_GOOGLE_ADS_KEYWORD';
export const SET_LOADING_KEYWORD = 'SET_LOADING_KEYWORD';
export const SET_CLEAR_STATE_GA = 'SET_CLEAR_STATE_GA';
export const GET_META_ADSETS = 'GET_META_ADSETS';
export const GET_TIKTOKS = 'GET_TIKTOKS';
export const GET_META_ADS_REPORT = 'GET_META_ADS_REPORT';
export const SET_LOADING_REPORT = 'SET_LOADING_REPORT';

//Ad Acccounts
export const GET_AD_ACCOUNT = 'GET_AD_ACCOUNT';
export const SAVE_AD_ACCOUNT = 'SAVE_AD_ACCOUNT';
export const UPDATE_AD_ACCOUNT = 'UPDATE_AD_ACCOUNT';
export const GET_AD_ACCOUNTS = 'GET_AD_ACCOUNTS';
export const SUBSCRIBE_WEBHOOK = 'SUBSCRIBE_WEBHOOK';
export const GET_TEAM_AD_ACCOUNTS = 'GET_TEAM_AD_ACCOUNTS';
export const REMOVE_ACCOUNT_FROM_TEAM = 'REMOVE_ACCOUNT_FROM_TEAM';

//User
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const GET_USER_BY_EMAIL = 'GET_USER_BY_EMAIL';
export const UPDATE_USER = 'UPDATE_USER';
export const CREATE_USER = 'CREATE_USER';
export const SEND_INVITATION = 'SEND_INVITATION';
export const GET_USERS_V3 = 'GET_USERS_V3';
export const DELETE_USER = 'DELETE_USER';

//Team
export const CREATE_TEAM = 'CREATE_TEAM';
export const GET_TEAM = 'GET_TEAM';
export const SET_TEAM = 'SET_TEAM';
export const GET_TEAMS = 'GET_TEAMS';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const ADD_QUICK_RESPONSE_TO_TEAM = 'ADD_QUICK_RESPONSE_TO_TEAM';
export const UPDATE_QUICK_RESPONSE_IN_TEAM = 'UPDATE_QUICK_RESPONSE_IN_TEAM';
export const REMOVE_QUICK_RESPONSE_FROM_TEAM = 'REMOVE_QUICK_RESPONSE_FROM_TEAM';
export const ADD_TAG_TO_TEAM = 'ADD_TAG_TO_TEAM';
export const UPDATE_TAG_IN_TEAM = 'UPDATE_TAG_IN_TEAM';
export const REMOVE_TAG_FROM_TEAM = 'REMOVE_TAG_FROM_TEAM';

//Facebook
export const GET_FB_RESOURCE = 'GET_FB_RESOURCE';
export const GET_FACEBOOK_DOCUMENT = 'GET_FACEBOOK_DOCUMENT';
export const CREATE_FACEBOOK_DOCUMENT = 'CREATE_FACEBOOK_DOCUMENT';
export const UPDATE_FACEBOOK_DOCUMENT = 'UPDATE_FACEBOOK_DOCUMENT';
export const RESEND_FACEBOOK_LEAD = 'RESEND_FACEBOOK_LEAD';

export const SET_LOADING_LEADGEN = 'SET_LOADING_LEADGEN';
export const SET_ERROR_LEADGEN = 'SET_ERROR_LEADGEN';

// static Data
export const GET_STATIC_LOOKUP_DATA = 'GET_STATIC_LOOKUP_DATA';

// Conversations
export const GET_CONVERSATIONS = 'GET_CONVERSATIONS';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const GET_CONVERSATION = 'GET_CONVERSATION';
export const MESSAGE_SOCKET = 'MESSAGE_SOCKET';
export const SET_FINAL = 'SET_FINAL';
export const CONVERSATION_SOCKET = 'CONVERSATION_SOCKET';
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION';
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';
export const CLEAR_ERROR = 'CLEAR_ERROR';

//hooks
export const GET_HOOKS = 'GET_HOOKS';
export const GET_HOOK = 'GET_HOOK';
export const UPDATE_HOOK = 'UPDATE_HOOK';
export const SEND_HOOK = 'SEND_HOOK';

//  Templates
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const GET_TEMPLATE_V3 = 'GET_TEMPLATE_V3';

// Leads
export const CREATE_LEAD = 'CREATE_LEAD';
export const GET_LEAD = 'GET_LEAD';
export const GET_LEADS = 'GET_LEADS';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const SEND_EMAIL = 'SEND_EMAIL';

// Tasks
export const GET_TASK = 'GET_TASK';
export const GET_TASKS = 'GET_TASKS';

// Appointments
export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const GET_APPOINTMENT = 'GET_APPOINTMENT';
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';

// Visits
export const CREATE_VISIT = 'CREATE_VISIT';
export const GET_VISIT = 'GET_VISIT';
export const GET_VISITS = 'GET_VISITS';
export const UPDATE_VISIT = 'UPDATE_VISIT';

// Sales
export const CREATE_SALE = 'CREATE_SALE';
export const GET_SALE = 'GET_SALE';
export const GET_SALES = 'GET_SALES';
export const UPDATE_SALE = 'UPDATE_SALE';

// Events
export const GET_EVENT = 'GET_EVENT';
export const GET_EVENTS = 'GET_EVENTS';
export const CLEAR_EVENT = 'CLEAR_EVENT';

// Reports
export const GET_REPORT = 'GET_REPORT';
export const CREATE_REPORT = 'CREATE_REPORT';
export const SET_SUCCESS = 'SET_SUCCESS';

//  Pockets
export const GET_POCKETS = 'GET_POCKETS';
export const GET_POCKET = 'GET_POCKET';
export const CREATE_POCKET = 'CREATE_POCKET';
export const DELETE_POCKET = 'DELETE_POCKET';
export const UPDATE_POCKET = 'UPDATE_POCKET';

export const CLEAR_SNACKBAR = 'CLEAR_SNACKBAR';

// Notes
export const CREATE_NOTE = 'CREATE_NOTE';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';

// Tasks
export const CREATE_TASK = 'CREATE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const DELETE_TASK = 'DELETE_TASK';
// Events
export const CREATE_EVENT = 'CREATE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
