import CustomAlert from 'components/CustomAlert';
import {
  GET_USERS,
  GET_USER_BY_EMAIL,
  GET_USER,
  UPDATE_USER,
  CREATE_USER,
  SET_ERROR,
  SET_LOADING,
  CLEAR_STATE,
  SEND_INVITATION,
  GET_USERS_V3,
  DELETE_USER
} from '../types';
import { CLEAR_ALL_USER_STATE, DEFAULT_USER_STATE } from './constants';

const removeUserFromUsers = (userId, users) => users.filter((user) => user?._id !== userId);

// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload.data,
        loading: false,
        count: action.count,
        error: null
      };
    case GET_USERS_V3:
      return {
        ...state,
        users: action.payload.data,
        loading: false,
        count: action.payload.count,
        error: null
      };
    case GET_USER_BY_EMAIL:
      return {
        ...state,
        usersEmail: action.payload,
        loading: false,
        count: action.count,
        error: null
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null
      };
    case CREATE_USER: {
      return {
        ...state,
        user: action.payload,
        users: [action.payload, ...state.users],
        count: (state.count || 0) + 1,
        loading: false,
        error: null,
        success: action.successMessage
      };
    }

    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case CLEAR_STATE:
      if (action.fieldsToClear.includes(CLEAR_ALL_USER_STATE)) {
        state = DEFAULT_USER_STATE;
      } else if (action?.fieldsToClear?.[0]) {
        action?.fieldsToClear.forEach((field) => (state[field] = DEFAULT_USER_STATE[field]));
      }
      return {
        ...state,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SEND_INVITATION:
      let userIndex = state?.users.findIndex((user) => user?._id === action?.payload?._id);
      if (userIndex < 0) state.users = [action.payload, ...state?.users];
      return {
        ...state,
        success: true,
        loading: false,
        error: null
      };
    case DELETE_USER:
      if (action?.payload?._id) state.users = removeUserFromUsers(action.payload._id, state.users);
      return {
        ...state,
        success: true,
        loading: false,
        error: null
      };
    default:
      return state;
  }
};
