export const DEFAULT_LEAD_STATE = {
  leads: [],
  lead: null,
  notes: [],
  history: [],
  tasks: [],
  count: 0,
  events: [],
  tags: [],
  loading: false,
  error: null,
  emailSentSuccesfully: false,
  success: null
};
