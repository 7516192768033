import React, { createContext, useReducer } from 'react';

import { useSnackbar } from 'notistack';
import { SnackBar } from 'components/SnackBar';

import pocketsReducer from './pocketsReducer';

import api from '../../api/api';

import { DEFAULT_STATE_POCKET } from './constants';

import { SET_LOADING, CREATE_POCKET, SET_ERROR, CLEAR_STATE, GET_POCKET, UPDATE_POCKET, DELETE_POCKET, GET_POCKETS } from '../types';
import { useTranslation } from 'hooks/useTranslation';
import { useRouter } from 'next/router';

export const PocketsContext = createContext(DEFAULT_STATE_POCKET);

export const PocketState = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useTranslation();
  const router = useRouter();

  const [state, dispatch] = useReducer(pocketsReducer, DEFAULT_STATE_POCKET);

  const getPockets = async (values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/pockets/getDocuments`, { ...values }, config);
      const { data, pagination } = res.data;
      const payload = {
        data,
        count: pagination?.total
      };
      dispatch({ type: GET_POCKETS, payload: payload });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err });
    }
  };

  const createPocket = async ({ ...team }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.post(`/pockets`, { ...team }, config);
      enqueueSnackbar(translate('Pocket.Created'), {
        content: (key, message) => <SnackBar id={key} message={message} type="success" />,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
      });
      const pocket = res?.data?.data;
      dispatch({ type: CREATE_POCKET, payload: pocket });
      if (pocket?._id) router.push(`/dashboard/pockets/${pocket._id}`);
    } catch (err) {
      if (err instanceof Error) dispatch({ type: SET_ERROR, payload: err.message });
    }
  };

  const getPocket = async (templateId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.get(`/pockets/${templateId}`, config);
      dispatch({ type: GET_POCKET, payload: res.data.data });
    } catch (err) {
      if (err instanceof Error) dispatch({ type: SET_ERROR, payload: err.message });
    }
  };

  const updatePocket = async (id, values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.put(`/pockets/${id}`, { ...values }, config);
      dispatch({ type: UPDATE_POCKET, payload: res.data.data });
      enqueueSnackbar(translate('Pocket.Updated'), {
        content: (key, message) => <SnackBar id={key} message={message} type="success" />,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
      });
    } catch (err) {
      if (err instanceof Error) dispatch({ type: SET_ERROR, payload: err.message });
    }
  };

  const deletePocket = async (pocketId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.delete(`/pockets/${pocketId}`, config);
      dispatch({
        type: DELETE_POCKET,
        payload: res.data.deletedId
      });
    } catch (err) {
      if (err instanceof Error) dispatch({ type: SET_ERROR, payload: err.message });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <PocketsContext.Provider
      value={{
        ...state,
        setLoading,
        clearState,
        createPocket,
        updatePocket,
        deletePocket,
        getPocket,
        getPockets
      }}
    >
      {props.children}
    </PocketsContext.Provider>
  );
};
