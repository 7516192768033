import CustomAlert from 'components/CustomAlert';
import ErrorShowTime from '../../constants/ErrorShowTime';
import {
  CREATE_EVENT,
  DELETE_EVENT,
  CLEAR_STATE,
  SET_LOADING,
  SET_ERROR,
  GET_EVENT,
  UPDATE_EVENT,
  GET_EVENTS,
  CLEAR_EVENT,
  CLEAR_SNACKBAR
} from '../types';
import { DEFAULT_EVENT_STATE } from './constants';

//eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case CREATE_EVENT: {
      return {
        ...state,
        events: [...state.events, action.payload],
        event: action.payload,
        loading: false,
        error: null,
        success: action.successMessage
      };
    }
    case DELETE_EVENT: {
      const newEvents = state.events.filter((event) => event?._id !== action?.payload?.data);

      return {
        ...state,
        events: newEvents,
        event: {},
        loading: false,
        success: action.successMessage,
        error: null
      };
    }
    case GET_EVENT: {
      return {
        ...state,
        event: action.payload,
        loading: false,
        error: null
      };
    }
    case GET_EVENTS: {
      return {
        ...state,
        events: action.payload,
        loading: false,
        error: null
      };
    }
    case UPDATE_EVENT: {
      return {
        ...state,
        events: state.events.map((event) => (event._id.toString() === action.payload._id.toString() ? action.payload : event)),
        event: action.payload,
        loading: false,
        error: null,
        success: action.successMessage
      };
    }
    case CLEAR_EVENT:
      return {
        ...state,
        event: null,
        loading: false,
        error: null
      };
    case CLEAR_STATE:
      return {
        ...state,
        ...DEFAULT_EVENT_STATE
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ERROR:
      CustomAlert({
        title: action?.payload,
        icon: 'error',
        timer: ErrorShowTime
      });
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case CLEAR_SNACKBAR:
      return { ...state, success: null, error: null };
    default:
      return { ...state };
  }
};
