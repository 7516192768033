import {
  CREATE_TEAM,
  CLEAR_STATE,
  SET_LOADING,
  SET_ERROR,
  GET_TEAM,
  UPDATE_TEAM,
  GET_TEAMS,
  SET_TEAM,
  ADD_QUICK_RESPONSE_TO_TEAM,
  UPDATE_QUICK_RESPONSE_IN_TEAM,
  REMOVE_QUICK_RESPONSE_FROM_TEAM,
  ADD_TAG_TO_TEAM,
  UPDATE_TAG_IN_TEAM,
  REMOVE_TAG_FROM_TEAM
} from '../types';

import { DEFAULT_TEAM_STATE } from './TeamState';

// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case CREATE_TEAM: {
      if (action?.setState) state.team = action.payload;
      return {
        ...state,
        teams: [...state?.teams, action.payload],
        loading: false,
        error: null,
        success: true
      };
    }
    case GET_TEAM: {
      return {
        ...state,
        team: action.payload,
        loading: false,
        error: null
      };
    }
    case SET_TEAM: {
      return {
        ...state,
        team: action.payload,
        loading: false,
        error: null
      };
    }
    case ADD_QUICK_RESPONSE_TO_TEAM:
    case UPDATE_QUICK_RESPONSE_IN_TEAM:
    case ADD_TAG_TO_TEAM:
    case UPDATE_TAG_IN_TEAM:
    case UPDATE_TEAM: {
      let teamIndex = state?.teams.findIndex((team) => team?._id === action?.payload?._id);
      if (teamIndex >= 0) state.teams[teamIndex] = action.payload;
      return {
        ...state,
        team: action.payload,
        loading: false,
        error: null,
        success: true
      };
    }
    case REMOVE_QUICK_RESPONSE_FROM_TEAM: {
      if (state.team) {
        const updatedQuickResponses = state.team.quickResponses.filter((response) => response._id.toString() !== action.payload.toString());
        return {
          ...state,
          team: {
            ...state.team,
            quickResponses: updatedQuickResponses
          },
          loading: false,
          error: null
        };
      }
      return state;
    }
    case REMOVE_TAG_FROM_TEAM: {
      if (state.team) {
        const updatedTags = state.team.tags.filter((response) => response._id.toString() !== action.payload.toString());
        return {
          ...state,
          team: {
            ...state.team,
            tags: updatedTags
          },
          loading: false,
          error: null
        };
      }
      return state;
    }
    case CLEAR_STATE:
      return {
        ...state,
        ...DEFAULT_TEAM_STATE
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: false
      };
    case GET_TEAMS: {
      return {
        ...state,
        teams: action.payload,
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};
