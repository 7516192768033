import { useEvent } from 'hooks/useEvent';
import {
  CREATE_LEAD,
  CLEAR_STATE,
  SET_LOADING,
  SET_ERROR,
  GET_LEAD,
  UPDATE_LEAD,
  GET_LEADS,
  SEND_EMAIL,
  CLEAR_SNACKBAR,
  CREATE_NOTE,
  UPDATE_NOTE,
  DELETE_NOTE,
  CREATE_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  CREATE_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT
} from '../types';
import { DEFAULT_LEAD_STATE } from './constants';
//eslint-disable-next-line
export default (state, action) => {
  const { updateStateEvent, deleteStateEvent } = useEvent();

  switch (action.type) {
    case DELETE_TASK: {
      return {
        ...state,
        tasks: state.tasks.filter((task) => task._id !== action.payload.task),
        history: [action.payload.activity, ...(state?.history || [])],
        success: action.successMessage
      };
    }
    case UPDATE_TASK: {
      return {
        ...state,
        tasks: state.tasks.map((task) => (task._id === action.payload.task._id ? action.payload.task : task)),
        history: [action.payload.activity, ...(state?.history || [])],
        success: action.successMessage
      };
    }
    case CREATE_TASK: {
      return {
        ...state,
        tasks: [action.payload.task, ...(state?.tasks || [])],
        history: [action.payload.activity, ...(state?.history || [])],
        success: action.successMessage
      };
    }

    case DELETE_EVENT: {
      deleteStateEvent(action.payload.event);
      return {
        ...state,
        events: state.events.filter((event) => event._id !== action.payload.event),
        history: [action.payload.activity, ...(state?.history || [])],
        success: action.successMessage
      };
    }
    case UPDATE_EVENT: {
      updateStateEvent(action.payload.event);
      return {
        ...state,
        loading: false,
        events: state.events.map((event) => (event._id === action.payload.event._id ? action.payload.event : event)),
        history: [action.payload.activity, ...(state?.history || [])],
        success: action.successMessage
      };
    }
    case CREATE_EVENT: {
      return {
        ...state,
        loading: false,
        events: [action.payload.event, ...(state?.events || [])],
        history: [action.payload.activity, ...(state?.history || [])],
        success: action.successMessage
      };
    }
    case DELETE_NOTE: {
      return {
        ...state,
        notes: state.notes.filter((note) => note._id !== action.payload.note),
        history: [action.payload.activity, ...(state?.history || [])],
        success: action.successMessage
      };
    }
    case UPDATE_NOTE: {
      return {
        ...state,
        loading: false,
        notes: state.notes.map((note) => (note._id === action.payload.note._id ? action.payload.note : note)),
        history: [action.payload.activity, ...(state?.history || [])],
        success: action.successMessage
      };
    }
    case CREATE_NOTE: {
      return {
        ...state,
        loading: false,
        notes: [action.payload.note, ...(state?.notes || [])],
        history: [action.payload.activity, ...(state?.history || [])],
        success: action.successMessage
      };
    }
    case SEND_EMAIL: {
      return {
        ...state,
        history: [action.payload, ...(state?.history || [])],
        loading: false,
        error: null,
        success: action.successMessage
      };
    }
    case CLEAR_SNACKBAR:
      return { ...state, success: null, error: null };
    case CREATE_LEAD: {
      return {
        ...state,
        lead: action.payload,
        leads: [action.payload, ...state.leads],
        count: (state.count || 0) + 1,
        loading: false,
        error: null,
        success: action.successMessage
      };
    }
    case GET_LEAD: {
      const { lead, notes, history, tasks, events, tags } = action.payload;
      return {
        ...state,
        lead,
        notes,
        tasks,
        history,
        events,
        tags,
        loading: false,
        error: null
      };
    }

    case GET_LEADS: {
      return {
        ...state,
        count: action?.count || 0,
        leads: action.payload,
        loading: false,
        error: null
      };
    }
    case UPDATE_LEAD: {
      const { lead, notes, history, tasks, events, tags } = action.payload;
      return {
        ...state,
        lead,
        notes,
        history,
        tasks,
        events,
        tags,
        loading: false,
        error: null
      };
    }
    case CLEAR_STATE:
      return {
        ...state,
        ...DEFAULT_LEAD_STATE
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return { ...state };
  }
};
